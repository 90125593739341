@import 'common';
:root {
	@each $color, $value in $colors {
		--#{$color}: #{$value};
	}
	@each $postfix, $colors in ('': $alert-colors, '-dark': $alert-colors-dark) {
		@each $color, $value in $colors {
			--#{$color}#{$postfix}: #{$value};
		}
	}

	@each $bp, $value in $grid-breakpoints {
		--breakpoint-#{$bp}: #{$value};
	}

	--font-family-sans-serif: #{inspect($font-family-sans-serif)};
	--font-family-monospace: #{inspect($font-family-monospace)};
	--font-family-h1: #{inspect($font-family-h1)};
	--font-family-h2: #{inspect($font-family-h2)};
}
